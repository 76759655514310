<template>
  <div class="view pa24">
    <div class="d-flex justify-content-between flex-wrap-y align-items-center">
      <div class="operation-left"></div>
      <div class="operation-right d-flex">
        <el-radio-group
          class="mr-16 d-flex align-items-center"
          @change="getMemberOrderList"
          v-model="searchData.orderState"
        >
          <el-radio-button label="all">全部</el-radio-button>
          <el-radio-button label="0">待付款</el-radio-button>
          <el-radio-button label="1">已付款</el-radio-button>
          <el-radio-button label="3">取消订单</el-radio-button>
        </el-radio-group>
        <el-input
          v-model="searchData.orderNum"
          class="mr-16 d-flex align-items-center"
          placeholder="请输入完整的订单号"
        ></el-input>
        <el-button
          style="height: 32px"
          type="primary"
          @click="getMemberOrderList"
          >查询</el-button
        >
      </div>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column type="index" align="center" label="序号" />
        <el-table-column
          prop="orderNum"
          align="center"
          label="订单号"
          show-overflow-tooltip
        />
        <el-table-column
          prop="levelName"
          align="center"
          label="商品"
          show-overflow-tooltip
        />
        <el-table-column
          prop="nickeName"
          align="center"
          label="会员昵称"
          show-overflow-tooltip
        />
        <el-table-column
          prop="phoneNum"
          align="center"
          label="联系电话"
          show-overflow-tooltip
        />
        <el-table-column
          show-overflow-tooltip
          prop="orderMoney"
          align="center"
          label="订单金额"
          width="90"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="payMoney"
          align="center"
          label="支付金额"
          width="90"
        >
        </el-table-column>
        <!-- <el-table-column
          show-overflow-tooltip
          prop="payType"
          align="center"
          label="支付方式"
          width="90"
        >
          <template slot-scope="scope">
            {{ scope.row.payType | getPayType }}
          </template>
        </el-table-column> -->
        <el-table-column
          show-overflow-tooltip
          prop="orderState"
          align="center"
          label="订单状态"
          width="90"
        >
          <template slot-scope="scope">
            {{ scope.row.orderState | getOrderState }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              class="mr10"
              type="text"
              @click="showDetailsModelFun(scope.row)"
              >查看详情</el-button
            >
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false"  
      title="订单详情"
      :visible.sync="showDetailsModel"
      
      :destroy-on-close="true"
      width="500px"
      center
    >
      <el-form ref="formData" label-width="100px">
        <el-form-item label="订单号：" prop="taskName">
          {{ orderDetails.orderNum }}
          <el-button type="text" @click="copyFun(orderDetails.orderNum)"
            >复制</el-button
          >
        </el-form-item>
        <el-form-item label="商品：" prop="taskName">
          {{ orderDetails.levelName }}
        </el-form-item>
        <el-form-item label="商品价格：" prop="taskName">
          {{ orderDetails.orderMoney }}
        </el-form-item>
        <el-form-item label="联系人：" prop="taskName">
          {{ orderDetails.nickeName }}
        </el-form-item>
        <el-form-item label="联系电话：" prop="taskName">
          {{ orderDetails.phoneNum }}
        </el-form-item>
        <el-form-item label="付款状态：" prop="taskName">
          <!-- <el-radio-group v-model="orderDetails.orderState" size="small">
            <el-radio-button :label="1">已付款</el-radio-button>
            <el-radio-button :label="0">未付款</el-radio-button>
          </el-radio-group> -->
          <el-tag v-if="orderDetails.orderState===1">已付款</el-tag>
          <el-tag v-if="orderDetails.orderState===0" type="info">未付款</el-tag>
        </el-form-item>
         <el-form-item label="支付时间：" prop="taskName">
          {{ orderDetails.payTime }}
        </el-form-item>
        <el-form-item label="下单时间：" prop="taskName">
          {{ orderDetails.orderTime }}
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetailsModel = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getMemberOrderList } from "@/api/member";
import { getDataTimeSec } from "@/utils";
export default {
  name: "customerArticles",
  components: {
    commonTable,
  },
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      tableData: [],
      userInfo: this.$store.state.loginRoot.userInfo,
      searchData: {
        orderState: "all",
        orderNum: "",
      },
      showDetailsModel: false,
      orderDetails: {},
    };
  },
  filters: {
    /**@method 订单状态 */
    getOrderState(orderState) {
      let orderStateData = { 0: "待付款", 1: "已付款", 3: "取消订单" };
      return orderStateData[orderState];
    },
    // getPayType(payType) {
    //   let payTypeData = {
    //     0: "微信现金",
    //     1: "银行卡",
    //     2: "信用卡",
    //     3: "现金",
    //   };
    //   if(payType || payType===0){
    //     return payTypeData[payType]
    //   }else{
    //     return "-"
    //   }
    // },
  },
  created() {
    this.getMemberOrderList();
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  methods: {
    copyFun(text) {
      let tag = document.createElement("input");
      tag.setAttribute("id", "cp_hgz_input");
      tag.value = text;
      document.getElementsByTagName("body")[0].appendChild(tag);
      document.getElementById("cp_hgz_input").select();
      if(document.execCommand("copy")){
        this.$message({
          type:"success",
          message:"复制成功"
        })
      }
      document.getElementById("cp_hgz_input").remove();
    },
    /**@method 获取列表 */
    async getMemberOrderList(payload) {
      let params = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        companyId: this.userInfo.companyId,
        isPage:1,
        // companyId: 2,
        ...payload,
      };
      if (this.searchData.orderNum) {
        params.orderNum = this.searchData.orderNum;
      }
      if (this.searchData.orderState != "all") {
        params.orderState = this.searchData.orderState;
      }
      try {
        this.loading = true;
        const result = await getMemberOrderList(params,9);
        this.loading = false;

        const { data } = result;
        this.tableData = data.pageInfo.list;
        this.total = data.pageInfo.total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.pageSize = val;
      this.getMemberOrderList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMemberOrderList();
    },
    /**@method 跳转
     * @param {Object} val - 当前点击行的值
     */
    showDetailsModelFun(row) {
      this.orderDetails = row;
      this.showDetailsModel = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.mr-16 {
  margin-right: 16px;
}
.switchInput {
  display: flex;
  .selectType {
    width: 150px;
  }
  .levelPrice {
    width: 200px;
  }
  .levelTime {
    width: 200px;
  }
}
.operation-left {
  .el-button {
    width: 96px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: #51cbcd;
    }
  }
}
.operation-right {
  .el-button--primary {
    background: #51cbcd;
    border-color: #51cbcd;
  }
  .export {
    border: 1px solid rgba(81, 203, 205, 0.4);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #51cbcd;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.btnDel {
  color: #fd634e;
}
</style>